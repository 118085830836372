@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Bold/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Bold/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Medium/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/Medium/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/SemiBold/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/SemiBold/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

//----------------------------------*\
// Fonts
//----------------------------------*/

// primary font
$primary_font: 'Montserrat';

// font-weight
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;
$black: 900;

// Font size
$font-xs: 12px;
$font-sm: 14px;
$primary-fs: 16px;
$font-md: 18px;
$font-lg: 20px;
