@import './fonts.scss';
@import './variables';

.vertical-line {
  border: 1px solid $dark-white;
  height: 4.5rem;
}

.navbar {
  color: $dark-white;
}

body {
  font-family: $primary_font;
  // font-size: $primary-fs;
  font-weight: $regular;
}

// BTN Primary Agil Web - white?
.btn-primary-agil-web {
  color: $light;
  background-color: $primary;
  border-radius: 20px;
  border-color: $primary;
  font-size: 16px;
  font-weight: $regular;
  outline: none;
  padding: 0.4rem 1.4rem;
  height: 2.5rem;

  &:hover {
    color: $primary;
    background-color: $light;
    border-radius: 20px;
    border-color: $primary;
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

// botões dos modais de cadastro
.btn-modal-agil-web {
  width: 6.5rem;
  height: 2.5rem;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-modal-cancelar {
  background-color: #707070;

  &:hover {
    background-color: #707070;
    color: #fff;
  }
}

.btn-modal-salvar {
  background-color: $primary;
  width: auto;

  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.table_wrapper {
  width: 80%;
  margin: 1rem 0rem 1rem 14rem;
}

//create form crud utils

.header-modal-crud {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem 3.75rem;

  .headerIcon {
    width: 2.125rem;
    height: 2.125rem;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $light;
    background: linear-gradient(180deg, #F2902B -81.25%, #F18C22 -30.5%, #EE7A1D -6.94%, #EA6115 32.94%, #E85211 69.19%, #E74D0F 100%);;
  }

  h4 {
    font-size: 22px;
    font-weight: bold;

    background: linear-gradient(180deg, #F2902B -81.25%, #F18C22 -30.5%, #EE7A1D -6.94%, #EA6115 32.94%, #E85211 69.19%, #E74D0F 100%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.modal-create-crud-aw {
  .form-create-crud-aw {
    margin-top: 2rem;
    padding: 0 3rem;
    color: #707070;

    .row .col-input-form {
      padding: 0.75rem 1rem;

      .invalid-feedback {
        margin-bottom: -0.75rem;
      }
    }

    input {
      background-color: #e9ecef;
      box-shadow: none;
      border-radius: 0.375rem;

      &:focus {
        border: #707070;
      }
    }
  }

  footer {
    margin: 2rem 3rem;

    button {
      margin-right: 2rem;

      fa-icon {
        margin-right: 0.5rem;
      }
    }
  }
}

.custom-modal-content .modal-content {
  background-color: #f1f1f1;
}
