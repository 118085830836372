@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/theme.scss';
@import './assets/scss/utils.scss';
@import './assets/scss/fonts.scss';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


$ngx-datatable-background: #304148;
$ngx-datatable-box-shadow: 0;
$datatable-header-cell-background: #304148;
$datatable-header-border-bottom-color: #304148;
$datatable-header-cell-color: white;
$datatable-body-cell-color: white;
$ngx-datatable-default-background-hover: rgba(128, 128, 128, 0);

*{
  font-family: 'Inter', sans-serif;
  font-family: 'Rubik', sans-serif;
}



.material-icons-round {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


.mat-bottom-sheet-container {
  border-radius: 30px 30px 0 0;
}

.fullscreen-dialog {

  max-width: 100% !important;
  background: #FAF9F7;

}

mat-dialog-container {

  padding: 0 !important;


}

.agilweb-body {

  background: #FAF9F7;
  padding: 20px;
  max-width: 1300px;
  margin: 40px 30px;

}

.new-scale-btn{
  transition: .1s ease-in-out !important;
}

.new-scale-btn:hover{
  transform: scale(1.02);
}

.agilweb-body-work {

  background: #FAF9F7;
  padding: 20px;
  max-width: 1200px;
  margin: auto;

}

.mat-paginator-outer-container {

  background: #FAF9F7;
}


.table-footer .mat-paginator-outer-container {
  background: transparent;
}


body {
  background: #FAF9F7;
}


.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Round', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}


.rounded-dialog {

  border-radius: 25px !important;
  overflow-y: auto;
  max-width: 100% !important;
  background: #FAF9F7;



}





::-webkit-scrollbar {
  width: 4px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;

}


::-webkit-scrollbar-thumb {
  background: #ff6500;
  border-radius: 10px;

}

.agil-snackbar {
  color: #ffffff !important; 
  background-color: #ff6500 !important;
  border-color: #ff6500 !important;

  .mat-simple-snackbar-action {
    color: #ffffff !important;
  }
}

.new-scale-item-menu{
  transition: .1s ease-in-out !important;
}

.new-scale-item-menu:hover{
  transform: scale(1.01);
  border-radius: 10px;
}

.new-main-title{
  font-size: 1.7rem;
}

.new-icon-eye{
  color: #e74d10;
  transition: .1s ease-in-out;
}

.new-icon-eye:hover{
  transform: scale(1.05);
}