$primary: #f47921;
$secondary: #1d2a30;
$success: #18d26b;

//light-colors
$light-success: #e7faf0;
$light-error: #ffebeb;
$light-waiting: #e1e1e1;
$light-info: #dce7f4;

$warning: #f8bd0d;
//$danger: $red;
$light: #fafafa;
$dark: #2f2f2f;
$dark-white: #e1e6ed;
$dark-more-white: #edf1f5;
$gray: #707070;
$background-gray: #20508114;

$btn-edit-background-color: #e7faf0;
$icon-color-edit: #18d26b;

$btn-delete-background-color: #ffebeb;
$icon-color-delete: #ff4747;

// status service order
$em-progresso: #18d26b;
$em-espera: #1d2a30;
$cancelada: #b82525;
$finalizada: #2569b8;
